
  import ScreenSize from '@/mixins/ScreenSize';
  export default {
    name: 'SectionHeadlineWithTextAndYoutubeVideo',
    components: {
      RichText: () => import('@/components/elements/RichText'),
    },
    mixins: [ScreenSize],
    props: {
      section: {
        type: Object,
        required: false,
        default: () => {},
      },
      device: {
        type: String,
        required: false,
        default: 'mobile',
      },
    },
    serverCacheKey: function (props) {
      return (
        'section-headline-with-text-and-youtube-video-' +
        props.section.id +
        '-' +
        props.device
      );
    },
    data() {
      return {
        sizeOfVideoColumn: 0,
      };
    },
    computed: {
      customWidth() {
        const percentage = (this.sizeOfVideoColumn / 12) * 100;
        return this.$vuetify.breakpoint.width <=
          this.$vuetify.breakpoint.thresholds.sm
          ? '100%'
          : percentage + '%';
      },
    },
    created() {
      this.sizeOfVideoColumn = this.section.video_width
        ? Number(this.section.video_width.split('_')[1])
        : 6;
    },
  };
